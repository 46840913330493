import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Visit Gorilla Rwanda (+250) 788 613 968'
    }
  },
  {
    path: '/destination',
    name: 'Destination',
    component: () => import('../views/DestinationView.vue'),
    meta: {
      title: 'Visit Gorilla Rwanda - Destination (+250) 788 613 968'
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: 'About | Visit Gorilla Rwanda (+250) 788 613 968'
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/ServiceView.vue'),
    meta: {
      title: 'Services | Visit Gorilla Rwanda (+250) 788 613 968'
    }
  },
  {
    path: '/packages',
    name: 'Packages',
    component: () => import('../views/PackageView.vue'),
    meta: {
      title: 'Itineraries | Visit Gorilla Rwanda (+250) 788 613 968'
    }
  },
  {
    path: '/kigali-tours',
    name: 'KigaliTours',
    component: () => import('../views/PackageKigali.vue'),
    meta: {
      title: 'Kigali Tours Itineraries | Visit Gorilla Rwanda (+250) 788 613 968'
    }
  },
  {
    path: '/akagera-national-park',
    name: 'AkageranationalPark',
    component: () => import('../views/PackaggeAkagera.vue'),
    meta: {
      title: 'Akagera National Park Itineraries | Visit Gorilla Rwanda (+250) 788 613 968'
    }
  },
  {
    path: '/nyungwe-national-park',
    name: 'NationalPark',
    component: () => import('../views/PackageNyungwe.vue'),
    meta: {
      title: 'Nyungwe National Park Itineraries | Visit Gorilla Rwanda (+250) 788 613 968'
    }
  },
  {
    path: '/mountain-gorilla',
    name: 'Mountaingorilla',
    component: () => import('../views/PackageGorilla.vue'),
    meta: {
      title: 'Mountain Gorilla | Visit Gorilla Rwanda (+250) 788 613 968'
    }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/BlogView.vue'),
    meta: {
      title: 'Our Blog | Visit Gorilla Rwanda (+250) 788 613 968'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/ContactView.vue'),
    meta: {
      title: 'Contact | Visit Gorilla Rwanda (+250) 788 613 968'
    }
  },
  {
    path: '/booking',
    name: 'Booking',
    component: () => import('../views/BookingView.vue'),
    meta: {
      title: 'Booking | Visit Gorilla Rwanda (+250) 788 613 968'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to, from) => {
  document.title = to.meta.title || 'Visit Gorilla Rwanda (+250) 788 613 968'
})

export default router
